export const filter = {
    search: '',
    id : null,
    name: '',
    surname: '',
    patronymic : '',
    email: '',
    phone: '',
    last_login: '',
    locked: '',
    specialties: '',
    created_at: '',
    start_date: '',
    end_date: '',
    updated_at: '',
    role_id: null
};