<template>
  <div>
    <aside class="aside" :class="{ active: drawer }">
      <!-- MENU LIST -->
      <ul class="menu-list">
        <!-- menu item -->
        <li
          v-for="(item, index) in menu"
          v-can-or="item.permission"
          @mouseenter="handlerAction(item)"
          class="menu-list__item"
          :key="index"
        >
          <span
            v-if="item.link === '#'"
            class="menu-list__item-link"
            :class="{
              active: item.children.some((child) => child.link === $route.name),
            }"
          >
            <i :class="item.icon"></i>
            {{ $t(item.name) }}
          </span>
          <router-link
            v-else
            class="menu-list__item-link"
            :to="{ name: item.link }"
          >
            <i :class="item.icon"></i>
            {{ $t(item.name) }}
          </router-link>
        </li>
      </ul>

      <!-- SUB MENU -->
      <div
        @mouseenter="drawer = true"
        @mouseleave="drawer = false"
        class="sub-menu"
        :class="{ active: drawer }"
      >
        <h2 class="sub-menu__title">{{ $t(subMenu.name) }}</h2>
        <ul class="sub-menu-list">
          <!-- v-can="r.permission" -->
          <li
            @click="drawer = false"
            v-for="(r, i) in subMenu.children"
            :key="i"
          >
            <router-link class="sub-menu-list__item" :to="{ name: r.link }">
              <i class="fa-solid fa-arrow-right"></i>
              <span>
                {{ $t(r.name) }}
              </span>
            </router-link>
          </li>
        </ul>
      </div>

      <!-- <div @mouseenter="drawer = false" class="bg-opacity"></div> -->
    </aside>

    <aside class="mobile-aside" :class="{ active: mobileMenu }">
      <div class="close-mobile-aside" @click="toggleMobile()">
        <i class="el-icon-close"></i>
      </div>
      <ul class="mobile-menu-list">
        <li
          v-for="(item, index) in menu"
          @click="unwrapSubmenu($event)"
          v-can-or="item.permission"
          class="mobile-menu-list__item"
          :key="index"
        >
          <div class="submenu-wrap d-flex w-100" v-if="item.link === '#'">
            <div
              class="mobile-menu-list__item-link"
              :class="{
                active: item.children.some(
                  (child) => child.link === $route.name
                ),
              }"
            >
              <i :class="item.icon"></i>
              <p>{{ $t(item.name) }}</p>
              <i class="el-icon-arrow-down el-icon--right drop-mobile"></i>
            </div>

            <!-- Mobile sub menu -->
            <div class="mobile-sub-menu" :class="{ active: false }">
              <ul class="mobile-sub-menu-list">
                <li
                  @click="toggleMobile()"
                  v-for="(r, i) in item.children"
                  :key="i"
                >
                  <router-link
                    class="mobile-sub-menu-list__item"
                    :to="{ name: r.link }"
                  >
                    <i class="fa-solid fa-arrow-right"></i>
                    <span>
                      {{ $t(r.name) }}
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="w-100" v-else @click="toggleMobile()">
            <router-link
              class="mobile-menu-list__item-link"
              :to="{ name: item.link }"
            >
              <i :class="item.icon"></i>
              <p>{{ $t(item.name) }}</p>
            </router-link>
          </div>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewMenu",
  data() {
    return {
      drawer: false,
      mobileAside: false,
      subMenu: {},
      subMenuMobile: {},
      menu: [
        {
          icon: "flaticon-monitor",
          name: "message.Dashboard",
          link: "home",
          permission: [
            "home.active_patients",
            "home.patients",
            "home.orders",
            "home.patient_graph",
            "home.cost_transaction_graph",
            "home.transaction_graph",
            "home.order_graph",
          ],
        },
        {
          icon: "flaticon-stethoscope",
          name: "message.schedule",
          link: "schedules.index",
          permission: ["schedules"],
        },
        {
          icon: "flaticon-medical",
          name: "message.patients",
          link: "#",
          permission: [
            "patients.index",
            "orders",
            "printCenter.index",
            "printCenter.printCenter",
          ],
          children: [
            {
              link: "patients.index",
              name: "message.patients",
              permission: "patients.index",
            },
            {
              link: "orders.index",
              name: "message.orders",
              permission: "orders.index",
            },
            {
              link: "orderServiceTerm.index",
              name: "message.orderServiceTerm",
              permission: "",
            },
            {
              link: "printCenterList",
              name: "message.conclusions_history",
              permission: "printCenter.printCenter",
            },
          ],
        },
        {
          icon: "flaticon-wallet",
          name: "message.cashbox",
          link: "#",
          permission: [
            "payments",
            "cost_transactions",
            "currencies",
            "payment_types",
            "costs",
            "contracts",
          ],
          children: [
            {
              link: "payments.index",
              name: "message.payments",
              permission: "payments.index",
            },
            {
              link: "payments.report",
              name: "message.payment_reports",
              permission: "payments.index",
            },
            {
              link: "costTransactions.index",
              name: "message.payments_for_expenses",
              permission: "cost_transactions.index",
            },
            // {
            //   link: "currencies.index",
            //   name: "message.currency",
            //   permission: 'currencies.index'
            // },
            // {
            //   link: "paymentTypes.index",
            //   name: "message.payment_type",
            //   permission: 'payment_types.index'
            // },
            // {
            //   link: "costs.index",
            //   name: "message.flow_types",
            //   permission: 'costs.index'
            // },
            {
              link: "paymentBonus.index",
              name: "message.paymentBonusP",
              permission: "costs.index",
            },
            {
              link: "transactionScores.index",
              name: "message.transactionScores",
              permission: "costs.index",
            },
            {
              link: "invoice.index",
              name: "message.invoice",
              permission: "",
            },

            {
              link: "invoiceInsurance.index",
              name: "message.invoice_insurance",
              permission: "",
            },
            {
              link: "cashierStationary.index",
              name: "message.stasionary_cashier",
              permission: "",
            },
            {
              link: "debtors.index",
              name: "message.debtors",
              permission: "",
            },
          ],
        },
        {
          icon: "flaticon-report",
          name: "message.laboratory",
          link: "#",
          permission: ["nurse_cabinet.orders"],
          children: [
            {
              link: "nurse-cabinet.orders",
              name: "message.lab_nurse",
              permission: "nurse_cabinet.orders",
            },
          ],
        },
        {
          icon: "flaticon-report",
          name: "message.my_office",
          link: "#",
          permission: [
            "doctor_cabinet.patients",
            "doctor_cabinet.index",
            "doctor_cabinet.AllPatients",
          ],
          children: [
            {
              link: "doctor-cabinet.informal-patients",
              name: "message.my_informal_patients",
              permission: "doctor_cabinet.patients",
            },
            {
              link: "doctor-cabinet.patients",
              name: "message.my_patients",
              permission: "doctor_cabinet.patients",
            },
            {
              link: "doctor-cabinet.AllPatients",
              name: "message.my_conclusions",
              permission: "doctor_cabinet.AllPatients",
            },
            {
              link: "doctor-cabinet.outsource",
              name: "message.outsource",
              permission: "doctor_cabinet.outsource",
            },
            {
              link: "doctor-cabinet.outsource-party",
              name: "message.outsource_party",
              permission: "doctor_cabinet.outsource_party",
            },
          ],
        },
        {
          icon: "flaticon-report",
          name: "message.procedural_0",
          link: "#",
          permission: [
            "doctor_cabinet.patientMedicine",
            "doctor_cabinet.patientMedicineShow",
          ],
          children: [
            {
              link: "doctor-cabinet.patientMedicine",
              name: "message.procedural_list",
              permission: "doctor_cabinet.patientMedicine",
            },
            {
              link: "doctor-cabinet.patientMedicineShow",
              name: "message.procedural",
              permission: "doctor_cabinet.patientMedicineShow",
            },
          ],
        },
        {
          icon: "flaticon-report",
          name: "message.hospital",
          link: "hospital.index",
          permission: ["hospitals.index"],
        },
        {
          icon: "flaticon-report",
          name: "message.warehouse",
          link: "#",
          permission: [
            "warehouseProducts",
            "requirements",
            "measurements",
            "warehouses",
            "write_offs",
            "currencies",
            "categories",
            "suppliers",
            "products",
            "statuses",
          ],
          children: [
            {
              link: "buyReadyProducts.index",
              name: "message.procurement",
              permission: "",
            },
            {
              link: "stockBunk.index",
              name: "message.warehouses_0",
              permission: "",
            },
            {
              link: "requirement.index",
              name: "message.requirement",
              permission: "",
            },
            {
              link: "moving.index",
              name: "message.movement",
              permission: "",
            },
            {
              link: "writeOffs.index",
              name: "message.write_offs",
              permission: "",
            },
            {
              link: "stocksWarehouses.index",
              name: "message.remainder",
              permission: "",
            },
            {
              link: "stocksWarehouses.consuption",
              name: "message.materials_consuption",
              permission: "",
            },
            {
              link: "ProductsWarehouses.index",
              name: "message.products",
              permission: "",
            },
            {
              link: "stocksSryaWarehouses.index",
              name: "message.warehouses",
              permission: "",
            },
            {
              link: "groupsWarehouses.index",
              name: "message.groups",
              permission: "",
            },
            {
              link: "measurementsWarehouses",
              name: "message.measurements",
              permission: "",
            },
            {
              link: "warehouseProvider.index",
              name: "message.suppliers",
              permission: "",
            },
            {
              link: "contractWarehouses.index",
              name: "message.suppliers_aggrement",
              permission: "",
            },
            {
              link: "statuses",
              name: "message.status",
              permission: "",
            },
            {
              link: "neededPills.index",
              name: "message.medicines_on",
              permission: "",
            },
          ],
        },
        {
          icon: "flaticon-medical",
          name: "message.reports",
          link: "reportCategory.index",
          permission: ["doctors_report", "excelReports", "partner_doctors"],
        },
        {
          icon: "flaticon-settings",
          name: "message.settings",
          link: "settingsCategory.index",
          permission: [
            "settings.index"
            // "work_times",
            // "settings",
            // "socials",
            // "tags",
            // "specialties",
            // "category_services",
            // "services",
            // "partner_doctor",
            // "hours",
            // "conditions",
          ],
        },
      ],

      env_user: parseInt(process.env.VUE_APP_USER),
      need_pill: parseInt(process.env.VUE_APP_NEED_PILL),
      env_state: parseInt(process.env.VUE_APP_STATE),
      env_region: parseInt(process.env.VUE_APP_REGION),
      env_city: parseInt(process.env.VUE_APP_CITY),
    };
  },

  methods: {
    handlerAction(param) {
      this.subMenu = param;
      if (
        param &&
        (param.link !== "#" || param.name.toLowerCase() === "dashboard")
      ) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    toggleMobile() {
      this.$store.commit("TOGGLE_MOBILE_MENU");
    },
    unwrapSubmenu(el) {
      const allChilds = document.querySelectorAll(".mobile-sub-menu");
      const allParents = document.querySelectorAll(
        ".mobile-menu-list__item-link"
      );
      const parent = el.target;
      const child = parent.parentNode.childNodes[1];

      if (child) {
        allChilds.forEach((i) => {
          if (i !== child && i.classList) {
            i.classList.remove("active");
          }
        });

        allParents.forEach((i) => {
          if (i !== parent && i.classList) {
            i.classList.remove("unwrap");
          }
        });

        parent.classList.toggle("unwrap");
        child.classList.toggle("active");
      }
    },
  },
  computed: {
    ...mapGetters({
      mobileMenu: "mobileMenu",
    }),
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  position: relative;
  background: #fff;
  min-height: 100vh;
  height: 100vh;
  padding-top: 72.5px;

  &.active {
    box-shadow: 1px 0px 4px rgba($color: #303030, $alpha: 0.2);
  }
}

// MENU LIST START
.menu-list {
  position: relative;
  display: flex;
  flex-direction: column;
  direction: rtl;
  gap: 6px;
  list-style: none;
  padding: 0 5px 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  z-index: 10;

  // scrool bar style
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #ebeef5;
    border: 2px solid #fff0;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #8a909c;
    border-color: #8a909c;
  }

  // menu-list__item-link
  &__item-link {
    width: 100px;
    direction: ltr;
    font-size: 12px;
    font-weight: 500;
    color: #727b89;
    background-color: transparent;
    margin: 0;
    padding: 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &.active,
    &.active:hover,
    &.router-link-exact-active,
    &.router-link-exact-active:hover {
      color: #fff;
      background-image: linear-gradient(
        to left bottom,
        #0d67c9,
        #0080d0,
        #0090ab,
        #00986a,
        #39991a
      ) !important;
      border-radius: 5px;
    }

    &:hover {
      color: #0c67c9;
      background: #e3f1ff;
    }

    i {
      font-size: 20px;
    }
  }
}

// SAUB MENU START
.sub-menu {
  padding: 72.5px 1.2rem 1rem 1.2rem;
  direction: ltr;
  width: 30vw;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 110px;
  background-color: rgba($color: #fff, $alpha: 1);
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 1px 0px 4px rgba($color: #303030, $alpha: 0.2);
  z-index: -1;
  transform: translateX(-120%);
  backdrop-filter: blur(5px);
  transition: all 0.2s linear;

  &.active {
    transform: translateX(0);
  }

  @media screen and (max-width: 1200px) {
    width: 35vw;
  }

  // sub-menu__title
  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #222;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  // sub-menu-list
  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    // sub-menu-list__item
    &__item {
      color: #55555a;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 4px 6px;
      border-radius: 5px;
      text-decoration: none;
      cursor: pointer;
      margin-bottom: 4px;
      transition: all 0.25s ease-in-out;

      span {
        transition: all 0.25s ease-in-out;
      }

      &.router-link-exact-active,
      &:hover {
        color: #0d67c9;
        // background: #bfdbfe;
        background: linear-gradient(
          to left,
          #dcedff,
          #d5f4ff,
          #d4f9f9,
          #dcfdf0,
          #ecffe6
        );
        // padding-left: 10px;

        i {
          color: #0d67c9;
        }
      }

      i {
        color: #91a2b6;
      }
    }
  }
}

.bg-opacity {
  background: rgba($color: #303030, $alpha: 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
</style>
