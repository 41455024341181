import {
    sort
} from "../properties/sort";
import {
    filter
} from "./properties/filter";
import {
    pagination
} from "../properties/pagination";
import {
    model
} from "./properties/model";
import {
    i18n
} from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, services) => (state.list = services),
    SET_LIST_LABORATORY: (state, services) => (state.laboratory = services),
    SET_COLOR: (state, services) => (state.color = services),
    SET_RELATION_LIST: (state, services) => (state.relation_list = services),
    SET_RELATION_FREE_LIST: (state, services) => (state.relation_free_list = services),
    SET_RELATION_LIST_ORDER: (state, services) => (state.relation_list_order = services),
    SET_SELECTED_RELATION_LIST: (state, services) => (state.selected_relation_list = services),
    SET_INVENTORY: (state, list) => state.inventory = list,
    SET_INVENTORY_ONLY_SERVICE_NAME: (state, inventory) => (state.inventory_service_name = inventory),
    SET_PARENT_INVENTORY: (state, list) => state.parent_inventory = list,
    SET_SERVICES_LIST: (state, data) => {
        for (const i in data) {
            if (data.hasOwnProperty(i)) {
                const service = data[i];
                let maxPosition = 0;
                let hasDuplicate = false;

                for (const key in state.selected_list) {
                    if (state.selected_list.hasOwnProperty(key)) {
                        const element = state.selected_list[key];
                        if (element.id == service.id) {
                            hasDuplicate = true;
                            maxPosition = Math.max(maxPosition, element.position || 0);
                        }
                    }
                }
                if (!service.count) service.count = 1;
                if (hasDuplicate) {
                    service.position = maxPosition + 1;
                } else {
                    service.position = 1;
                }
                service.price_of_discount = 0;

                state.selected_list.push(service);
            }
        }
    },
    UPDATE_SET_SERVICES_LIST: (state, data) => {
        for (const i in data) {
            if (data.hasOwnProperty(i)) {
                const service = data[i];
                let has = false;
                for (const key in state.selected_list) {
                    if (state.selected_list.hasOwnProperty(key)) {
                        const element = state.selected_list[key];
                        if (element.id == service.id) {
                            has = true;
                        }
                    }
                }
                if (!service.count) service.count = 1;
                if (!service.position) service.position = service.position ? service.position : 1;
                service.price_of_discount = 0;
                state.selected_list.push(service)
            }
        }
    },
    UPDATE_SERVICES_LIST: (state, data) => {
        for (const key in state.selected_list) {
            if (state.selected_list.hasOwnProperty(key)) {
                if (state.selected_list[key].id === data.id && state.selected_list[key].position === data.position) {
                    state.selected_list[key][data.key] = data.value
                }
            }
        }
    },
    ROMEVE_SERVICES_ITEM: (state, id) => {
        let list = state.selected_list
        for (const key in list) {
            if (list.hasOwnProperty(key)) {
                const element = list[key];
                if (element.id == id) {
                    list.splice(key, 1)
                }
            }
        }
    },
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_MODEL: (state, model) => {
        state.model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_SERVICES_LIST: (state) => {
        state.selected_list = []
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.name.title = i18n.t('message.name');
        state.columns.code.title = i18n.t('message.code');
        state.columns.price.title = i18n.t('message.current_price');
        state.columns.discount_price.title = i18n.t('message.discount_cost');
        state.columns.is_service.title = i18n.t('message.category_or_service');
        state.columns.parent_id.title = i18n.t('message.parent_service');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};