<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Номер Амбулаторной карты'"
    disabled
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['hospitalPatientId'], 
  watch: {
    selected: {
       handler: function(val, oldval){
        if (val) {
          if (this.lastHistory.id === "") {
            this.selectedNumber({id: this.hospitalPatientId})
          }
        }
      },
      deep: true,
    },
    lastHistory: {
      handler: function() {
        if (this.lastHistory) {
          this.selected = this.lastHistory.number;
          this.$emit("input", this.selected);          
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      lastHistory: 'patientHistories/model',
      hospitalCodes: 'patientHistories/inventory',
    }),
  },
  
  mounted() {
    this.getInventory({id: this.hospitalPatientId})
    .then(res => {
      this.selected = this.lastHistory.number;
      this.$emit("input", this.selected);
    });
  },
  methods: {
    ...mapActions({
      getInventory: 'patientHistories/inventory',
      selectedNumber: 'patientHistories/show',
    })
  },
};
</script>
